import React from 'react';

const NSPortalTransparencia = () => (
  <div>
    <h2>Consulta Governo Federal</h2>
    <ul className="ul-coleta">
      <li>
        <p>Despesas Governamentais:</p>
        <ul className="ul-coleta">
          <li>Gastos Diretos.</li>
          <li>Transferências.</li>
          <li>Bolsa Família - Pagamentos e Saques.</li>
          <li>Pescador Artesanal.</li>
        </ul>
      </li>
      <li>
        <p>Dados de Funcionários Públicos:</p>
        <ul className="ul-coleta">
          <li>SIAPE (Sistema Integrado de Administração de Pessoal).</li>
          <li>Militares.</li>
          <li>Outros Programas Sociais.</li>
        </ul>
      </li>
      <li>
        <p>Informações Financeiras e Contratuais:</p>
        <ul className="ul-coleta" style={{ marginRight: '5px' }}>
          <li>Receitas.</li>
          <li>Convênios.</li>
          <li>Servidores Públicos.</li>
          <li>Cadastro de Empresas Inidôneas e Suspensas (CEIS).</li>
          <li>Cadastro de Entidades sem Fins Lucrativos Impedidas.</li>
          <li>Imóveis Funcionais.</li>
        </ul>
      </li>
      <br></br>
      <p>Esses dados são coletados a partir de fontes públicas como o Portal da Transparência do governo federal (<span ><a href="http://transparencia.gov.br/" target="_blank" rel="noreferrer noopener" >transparencia.gov.br</a></span>) e outras plataformas de dados abertos.</p>
      <p>Nossa captura de dados é feita com foco na eficiência, segurança e respeito às legislações de proteção de dados, como a LGPD, garantindo que você tenha acesso a informações atualizadas para a gestão e o planejamento estratégico do seu negócio.</p>
    </ul>
  </div>
);

export default NSPortalTransparencia;
