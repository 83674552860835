// src/pages/LGPD.jsx
import React from 'react';
import './style/LGPD.css';

function LGPD() {
  return (
    <div className="lgpd-container container">
      <h1>Política de Privacidade e Proteção de Dados Pessoais</h1>
      <p>
        Em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018 - LGPD), nossa empresa adota práticas transparentes para proteger os dados pessoais de pessoas físicas e jurídicas. Valorizamos a privacidade e segurança das informações coletadas a partir de fontes públicas e portais de dados abertos.
      </p>
      <p>
        <strong>Coleta de Dados Pessoais</strong> Os dados coletados por nossa empresa são obtidos exclusivamente de fontes públicas e portais de dados abertos, disponíveis para consulta de acordo com as normas de transparência e acesso à informação. Não coletamos dados de fontes privadas sem o consentimento expresso de seus titulares.
      </p>
      <p>
        <strong>Finalidade do Tratamento de Dados</strong> Utilizamos os dados pessoais para as seguintes finalidades:
      </p>
      <ul>
        <li>Análise e processamento de informações públicas de acordo com as exigências legais e regulatórias.</li>
        <li>Desenvolvimento de soluções baseadas em dados para melhorar a experiência de nossos clientes.</li>
        <li>Garantir o cumprimento de obrigações legais e regulatórias.</li>
      </ul>
      <p>
        <strong>Compartilhamento de Dados</strong> Os dados tratados por nossa empresa poderão ser compartilhados com terceiros, sempre em conformidade com a legislação vigente e respeitando os princípios de necessidade, adequação e finalidade previstos na LGPD.
      </p>
      <p>
        <strong>Direitos dos Titulares de Dados</strong> De acordo com a LGPD, os titulares dos dados têm os seguintes direitos:
      </p>
      <ul>
        <li>Solicitar a confirmação da existência de tratamento de seus dados pessoais.</li>
        <li>Acessar os dados que mantemos sobre eles.</li>
        <li>Solicitar a correção de dados incompletos, inexatos ou desatualizados.</li>
        <li>Solicitar a exclusão de dados que foram tratados em desconformidade com a LGPD.</li>
        <li>Solicitar a portabilidade de seus dados pessoais.</li>
      </ul>
      <p>
        <strong>Segurança dos Dados</strong> Adotamos medidas técnicas e administrativas de segurança para proteger os dados pessoais contra acessos não autorizados, perda, alteração ou divulgação indevida.
      </p>
      <p>
        <strong>Contato</strong> Se você tiver dúvidas ou desejar exercer seus direitos como titular de dados pessoais, entre em contato conosco.
      </p>
      <p>
        <strong>Atualizações desta Política</strong> Nos reservamos o direito de atualizar esta política de tempos em tempos, de acordo com as mudanças na legislação ou em nossos processos de tratamento de dados.
      </p>
    </div>
  );
}

export default LGPD;
