import React from 'react';

const NSTelebloq = () => (
  <div>
    <h2>Consulta TELEBLOQUEIO - PROCON</h2>
    <p>
      Nossa solução permite a consulta automatizada de informações públicas referentes ao Telebloqueio, fornecendo dados sobre números de telefone que não podem receber chamadas de telemarketing, de acordo com as regulamentações estaduais e nacionais.
    </p>
    <p>
      Essa consulta é essencial para empresas que desejam garantir conformidade com as leis de proteção ao consumidor e evitar penalidades por contato indevido.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>DDD.</li>
      <li>Número de Telefone.</li>
      <li>Data de Cadastro do número na lista de telebloqueio.</li>
      <li>Evento (inclusão ou exclusão do bloqueio).</li>
      <li>Data do Evento.</li>
      <li>Estado de origem do cadastro.</li>
    </ul>
    <p>
      Esses dados são extraídos de fontes públicas e garantem que sua empresa esteja em conformidade com as regras de telemarketing, evitando o contato com números bloqueados e respeitando o direito do consumidor.
    </p>
    <p>
      Todo o processo é realizado em conformidade com a Lei Geral de Proteção de Dados (LGPD), assegurando que as informações sejam tratadas com segurança e sigilo.
    </p>
  </div>
);

export default NSTelebloq;
