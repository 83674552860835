import React from 'react';

const NSCNES = () => (
  <div>
    <h2>Consulta Cadastro Nacional de Estabelecimentos e Profissionais de Saúde</h2>
    <p>
      Oferecemos uma solução automatizada para consulta de dados do Cadastro Nacional de Estabelecimentos de Saúde (CNES), uma fonte pública disponível no Datasus (<a href="http://cnes.datasus.gov.br/" target="_blank" rel="noreferrer noopener">cnes.datasus.gov.br</a>).
    </p>
    <p>
      Através dessa consulta, é possível obter informações detalhadas sobre os estabelecimentos de saúde registrados, além de dados sobre infraestrutura, profissionais e serviços oferecidos.
    </p>
    <p>As informações que podem ser capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>Dados Cadastrais dos estabelecimentos de saúde.</li>
      <li>Profissionais SUS e Profissionais não SUS (quantidade e funções).</li>
      <li>Leitos disponíveis.</li>
      <li>Equipamentos de Infraestrutura.</li>
      <li>Equipamentos para Manutenção da Vida (aparelhos vitais para suporte à saúde).</li>
      <li>Resíduos/Rejeitos (gestão de resíduos hospitalares).</li>
      <li>Instalações Físicas para Assistência Ambulatorial.</li>
      <li>Serviços de Apoio (como laboratório e diagnóstico).</li>
      <li>Serviços Especializados (atendimentos especializados disponíveis).</li>
      <li>Serviços e Classificação (tipologia e classificação dos serviços oferecidos).</li>
    </ul>
    <p>
      Essas informações são fundamentais para análises estratégicas na área de saúde, controle de infraestrutura hospitalar, planejamento de recursos e monitoramento da capacidade de atendimento de cada estabelecimento. Além disso, todos os dados coletados são tratados com segurança e em conformidade com a Lei Geral de Proteção de Dados (LGPD), garantindo a proteção das informações.
    </p>
  </div>
);

export default NSCNES;
