import React, { useState } from 'react';
import NSEmpresas from './tabs/NSEmpresas.jsx';
import NSMedicos from './tabs/NSMedicos.jsx';
import NSSituacaoCadastral from './tabs/NSSituacaoCadastral.jsx';
import NSTelefones from './tabs/NSTelefones.jsx';
import NSSintegra from './tabs/NSSintegra.jsx';
import NSSimplesNacional from './tabs/NSSimplesNacional.jsx';
import NSUniversitarios from './tabs/NSUniversitarios.jsx';
import NSANP from './tabs/NSANP.jsx';
import NSFipe from './tabs/NSFipe.jsx';
import NSProdutorRural from './tabs/NSProdutorRural.jsx';
import NSPortalTransparencia from './tabs/NSPortalTransparencia.jsx';
import NSPerfilBR from './tabs/NSPerfilBR.jsx';
import NSPGFN from './tabs/NSPGFN.jsx';
import NSCustomizado from './tabs/NSCustomizado.jsx';
import NSTelebloq from './tabs/NSTelebloq.jsx';
import NSPortabilidade from './tabs/NSPortabilidade.jsx';
import NSCNES from './tabs/NSCNES.jsx';
import NSObitos from './tabs/NSObitos.jsx';
import NSTup from './tabs/NSTup.jsx';

import './style/ColetaDeDados.css';


const ColetaDeDados = () => {
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { title: "NS Customizado", component: <NSCustomizado /> },
        { title: "NS Empresas", component: <NSEmpresas /> },
        { title: "NS Médicos", component: <NSMedicos /> },
        { title: "NS Situação Cadastral", component: <NSSituacaoCadastral /> },
        { title: "NS Telefones", component: <NSTelefones /> },
        { title: "NS SINTEGRA", component: <NSSintegra /> },
        { title: "NS Simples Nacional", component: <NSSimplesNacional /> },
        { title: "NS Universitários", component: <NSUniversitarios /> },
        { title: "NS ANP", component: <NSANP /> },
        { title: "NS Fipe", component: <NSFipe /> },
        { title: "NS Produtor Rural", component: <NSProdutorRural /> },
        { title: "NS Portal Transparência", component: <NSPortalTransparencia /> },
        { title: "NS PERFIL BR", component: <NSPerfilBR /> },
        { title: "NS PGFN", component: <NSPGFN /> },
        { title: "NS Telebloq", component: <NSTelebloq /> },
        { title: "NS Portabilidade", component: <NSPortabilidade /> },
        { title: "NS CNES", component: <NSCNES /> },
        { title: "NS Óbitos", component: <NSObitos /> },
        { title: "NS TUP", component: <NSTup /> },
    ];

    return (
        <div className="coletaDeDados-container container">
            <div className="coletaDeDados-container-text">
                <h1>NS - Customização</h1>
                <h2>Obtenha as informações que você precisa!</h2>
                <p>
                    Nossas soluções são desenvolvidas com o objetivo de reduzir custos operacionais e de infraestrutura, automatizando a busca de informações de domínio público em sites, blogs, redes sociais e buscadores, com ou sem captcha. O foco é extrair grandes ou pequenas quantidades de dados de forma eficiente, no menor tempo possível e com o menor custo, permitindo que você:
                </p>
                <br></br>
                <ul className="ul-coleta">
                    <li>
                        <p><span>Enriqueça suas bases de dados.</span></p>
                    </li>
                    <li>
                        <p><span>Trace estratégias econômicas mais assertivas.</span></p>
                    </li>
                    <li>
                        <p><span>Faça planejamentos detalhados.</span></p>
                    </li>
                    <li>
                        <p><span>Conheça melhor seu público-alvo.</span></p>
                    </li>
                    <li>
                        <p><span>Antecipe problemas por meio de análises estatísticas.</span></p>
                    </li>
                </ul>
                <br></br>
                <p>Nossos serviços são ideais para empresas que buscam otimizar processos de coleta de dados e obter insights valiosos para tomada de decisão.</p>
            </div>
            {/* <h1>Coleta de Dados</h1> */}
            <div className="tab-buttons">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={activeTab === index ? 'active' : ''}
                        onClick={() => setActiveTab(index)}
                    >
                        {tab.title}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                {tabs[activeTab].component}
            </div>
        </div>
    );
};

export default ColetaDeDados;
