import React from 'react';

const NSProdutorRural = () => (
  <div>
    <h2>Consulta e Extração de Dados de Produtores Rurais - DAP</h2>
    <p>
      Oferecemos um serviço automatizado para a consulta e extração de dados de produtores rurais registrados na DAP (Declaração de Aptidão ao Pronaf), uma fonte pública utilizada para identificar agricultores familiares, produtores rurais e empresas do setor agrícola que têm acesso a programas de fomento e crédito rural.
    </p>
    <p>
      Essa consulta é essencial para empresas do setor agrícola, instituições financeiras, e organizações que atuam no desenvolvimento rural.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>Nome Completo do produtor rural ou Razão Social da empresa.</li>
      <li>CPF (para pessoa física) ou CNPJ (para empresas).</li>
      <li>Número da DAP.</li>
      <li>Categoria (familiar, empreendedor, empresa, etc.).</li>
      <li>Validade da DAP.</li>
      <li>Localidade (município e estado).</li>
      <li>Data da Consulta.</li>
    </ul>
    <p>
      Esses dados são fundamentais para instituições que desejam validar a regularidade de produtores rurais, sejam eles pessoas físicas ou jurídicas, realizar análise de crédito rural, e promover programas de incentivo ao agronegócio.
    </p>
    <p>
      Todo o processo de coleta é realizado de forma eficiente e em conformidade com a Lei Geral de Proteção de Dados (LGPD), assegurando a privacidade e a segurança das informações tratadas.
    </p>
  </div>
);

export default NSProdutorRural;
