import React from 'react';

const NSPGFN = () => (
  <div>
    <h2>Consulta Dívida Ativa da União</h2>
    <p>
      Oferecemos um serviço automatizado para a consulta de dados públicos referentes à Dívida Ativa, acessados diretamente do Portal da Procuradoria-Geral da Fazenda Nacional (PGFN) (<a href="http://www.pgfn.fazenda.gov.br/" target="_blank" rel="noreferrer noopener">pgfn.fazenda.gov.br</a>).
    </p>
    <p>
      Através dessa consulta, é possível obter informações detalhadas sobre débitos de pessoas físicas e jurídicas inscritos em dívida ativa, fornecendo uma visão clara sobre pendências financeiras e fiscais.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>CPF ou CNPJ do devedor.</li>
      <li>Nome ou Razão Social.</li>
      <li>Valor da Dívida.</li>
      <li>Data da Inscrição da dívida.</li>
    </ul>
    <p>
      Esses dados são fundamentais para empresas que buscam validar a situação financeira de clientes, parceiros ou fornecedores, auxiliando em processos de análise de crédito, compliance e due diligence.
    </p>
    <p>
      A coleta é realizada de forma rápida e segura, e todas as informações são tratadas em conformidade com a Lei Geral de Proteção de Dados (LGPD), garantindo a segurança e privacidade dos dados coletados.
    </p>
  </div>
);

export default NSPGFN;
