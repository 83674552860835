import React, { useEffect } from 'react';
import './style/Home.css';
import image01 from '../assets/image01.png';
import cloudComputin from '../assets/cloud-computing.png';
import computer from '../assets/computer.png';
import mobile from '../assets/mobile.png';
import { Link } from 'react-router-dom';

function Home() {
  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      },
      { threshold: 0.1 } // Pode ajustar o threshold conforme necessário
    );

    cards.forEach((card) => {
      observer.observe(card);
    });
  }, []);
  return (
    <div className="home-container">
      <div className="home-container-image">
        <div className="image-overlay">
          <h2>A <strong style={{ color: '#2B7EC8' }}>NS</strong> auxilia empresas na manutenção e atualização de dados.</h2>
          <p>
            Com anos de experiência no mercado, a NS está sempre inovando em suas soluções e aprimorando seu conhecimento para buscar, tratar, qualificar e enriquecer bases de dados operacionais e de negócios, utilizando informações de domínio público disponíveis na web. Nosso compromisso é garantir que as empresas tenham acesso a dados atualizados e precisos para otimizar suas operações.
          </p>
          <button className="saiba-mais"><Link to="/coleta-de-dados">Saiba mais</Link></button>
        </div>
        <img className="background-image" src={image01} alt="Main Image" />
      </div>
      <div className="cards-container">
        <div className="card">
          <img src={cloudComputin} alt="Segurança na nuvem" />
          <h3>Segurança na nuvem</h3>
          <p>
            Todos os nossos serviços e fontes de dados são criptografados, garantindo segurança e confidencialidade. Operamos com servidores dedicados e infraestrutura em nuvem em Data Centers localizados no Brasil, América do Norte, Europa e Ásia, assegurando alta disponibilidade e desempenho.
          </p>
        </div>
        <div className="card">
          <img src={computer} alt="Servidores de busca" />
          <h3>Servidores de busca</h3>
          <p>
            Nossos sistemas são desenvolvidos para automatizar pesquisas em sites, blogs, redes sociais e buscadores, garantindo eficiência e precisão na coleta de informações de domínio público.
          </p>
        </div>
        <div className="card">
          <img src={mobile} alt="Sistemas Web Mobile" />
          <h3>Sistemas Web Mobile</h3>
          <p>
            Nossa equipe é especialista no desenvolvimento ágil de sistemas web e mobile, adaptados às necessidades específicas de sua empresa ou projeto, entregando soluções sob medida para maximizar seus resultados.
          </p>
        </div>
      </div>

    </div>
  );
}

export default Home;
