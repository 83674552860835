import React, { useState, useEffect } from 'react';
import './style/Popup.css';

const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const popupAccepted = localStorage.getItem('popupAccepted');
    if (!popupAccepted) {
      setShowPopup(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('popupAccepted', 'true');
    setShowPopup(false);
  };

  if (!showPopup) return null;

  return (
    <div className="popup-container">
      <div className="popup-content">
        <h3>Cookies</h3>
        <p>Utilizamos cookies para permitir a melhor experiência em nosso website. <a href="https://nsmarketing.com.br/politica_de_privacidade.htm">Política de Privacidade</a></p>
        <button onClick={handleAccept}>OK</button>
      </div>
    </div>
  );
};

export default Popup;
