import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './templates/Home';
import Empresa from './templates/Empresa';
import ColetaDeDados from './templates/ColetaDeDados';
import Servicos from './templates/Servicos';
import LGPD from './templates/LGPD';
import Contato from './templates/Contato';
import PopUp from './components/Popup';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/index.html" element={<Home />} />
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/coleta-de-dados" element={<ColetaDeDados />} />
        <Route path="/servicos" element={<Servicos />} />
        <Route path="/lgpd" element={<LGPD />} />
        <Route path="/contato" element={<Contato />} />
      </Routes>
      <PopUp />
      <Footer />
    </Router>
  );
}

export default App;
