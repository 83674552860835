import React from 'react';

const NSEmpresas = () => (
  <div>
    <h2>Consulta Situação Cadastral PJ</h2>
    <p>
      Utilizamos tecnologias avançadas para automatizar a coleta de informações de domínio público de fontes confiáveis, como o site da Receita Federal do Brasil 
      (<a href="http://www.receita.fazenda.gov.br" target="_blank" rel="noreferrer noopener">receita.fazenda.gov.br</a>).
    </p>
    <p>As informações capturadas são essenciais para a atualização e enriquecimento de bases de dados empresariais e incluem:</p>
    <ul className="ul-coleta">
      <li>CNPJ.</li>
      <li>Razão Social.</li>
      <li>Capital Social.</li>
      <li>Nome/Nome Empresarial dos sócios.</li>
      <li>Qualificação dos sócios (como administrador, diretor, entre outros).</li>
      <li>Participação no Capital Social (percentual ou valor investido).</li>
      <li>Data de Abertura da empresa.</li>
      <li>Situação Cadastral (ativa, inapta, suspensa, etc.).</li>
      <li>Endereço Completo da empresa (logradouro, número, complemento, bairro, cidade, UF, CEP).</li>
      <li>Natureza Jurídica.</li>
      <li>Atividade Econômica Principal e Secundárias (CNAE).</li>
      <li>E-mail de Contato.</li>
      <li>Telefone de Contato.</li>
      <li>Data e Hora da Consulta.</li>
    </ul>
    <p>
      Além de capturar essas informações públicas, nossa solução oferece a capacidade de analisar e cruzar esses dados com outras bases, permitindo gerar valores presumidos de <strong>faturamento</strong>, 
      estimativas do <strong>número de funcionários</strong>, além de calcular o <strong>risco</strong> da empresa com base em critérios financeiros, operacionais e de mercado.
    </p>
    <p>
      Essas análises fornecem insights valiosos que podem auxiliar na tomada de decisões estratégicas, avaliação de crédito, gestão de risco e compliance.
    </p>
    <p>Todos os dados são processados com segurança e em conformidade com a Lei Geral de Proteção de Dados (LGPD).</p>
  </div>
);

export default NSEmpresas;
