import React from 'react';

const NSANP = () => (
  <div>
    <h2>Consulta ANP / Autorizações / Preços e Postos</h2>
    <p>
      Nossa solução automatizada permite a consulta de informações públicas fornecidas pela Agência Nacional do Petróleo, Gás Natural e Biocombustíveis (ANP).
    </p>
    <p>
      Através dessa consulta, é possível obter dados detalhados sobre postos de combustíveis, seus preços de venda e compra, e outros dados cadastrais relevantes, fundamentais para análises de mercado, planejamento estratégico e controle de preços.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>CNPJ do estabelecimento.</li>
      <li>Razão Social.</li>
      <li>Endereço Completo (logradouro, número, complemento, bairro, cidade, UF e CEP).</li>
      <li>Preço de Venda dos combustíveis.</li>
      <li>Preço de Compra praticado.</li>
      <li>Bandeira (marca do posto).</li>
      <li>Fornecedor de combustíveis.</li>
      <li>Modalidade de Compra (tipo de contrato de compra do combustível).</li>
      <li>Data da Coleta das informações.</li>
      <li>Data da Consulta realizada.</li>
    </ul>
    <p>
      Esses dados são extraídos diretamente de fontes públicas da ANP e são essenciais para empresas do setor de energia e combustíveis, oferecendo uma visão clara sobre a variação de preços, estratégias de compra, e a competitividade entre postos de combustíveis.
    </p>
    <p>
      A consulta é feita de maneira eficiente e segura, respeitando as diretrizes da Lei Geral de Proteção de Dados (LGPD), garantindo a privacidade das informações coletadas.
    </p>
  </div>
);

export default NSANP;
