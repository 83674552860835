import React from 'react';

const NSFipe = () => (
  <div>
    <h2>Consulta TABELA FIPE</h2>
    <p>
      Oferecemos um serviço automatizado para a consulta de informações públicas da Tabela FIPE, amplamente utilizada para referência de preços de veículos no Brasil.
    </p>
    <p>
      A consulta permite obter dados atualizados sobre valores de mercado de veículos, auxiliando em processos de avaliação de patrimônio, negociação e cálculo de seguros.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>Mês de Referência.</li>
      <li>Código FIPE.</li>
      <li>Marca do veículo.</li>
      <li>Modelo do veículo.</li>
      <li>Ano Modelo.</li>
      <li>Preço Médio de mercado.</li>
      <li>Data da Consulta.</li>
    </ul>
    <p>
      Esses dados são extraídos diretamente da Fundação Instituto de Pesquisas Econômicas (FIPE) 
      (<a href="http://www.fipe.org.br/pt-br/indices/veiculos/" target="_blank" rel="noreferrer noopener">fipe.org.br</a>) 
      e são essenciais para empresas que atuam nos setores de seguros, compra e venda de veículos, locação, ou qualquer área que exija a referência de preços de mercado de automóveis, motos e caminhões.
    </p>
    <p>
      Todo o processo é realizado de forma rápida e segura, com total conformidade à Lei Geral de Proteção de Dados (LGPD), garantindo a proteção e privacidade das informações tratadas.
    </p>
  </div>
);

export default NSFipe;
