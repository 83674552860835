import React from 'react';

const NSSintegra = () => (
  <div>
    <h2>Consulta e Extração de Dados do Sintegra</h2>
    <p>
      Oferecemos um serviço automatizado para a consulta e extração de dados do Sintegra (Sistema Integrado de Informações sobre Operações Interestaduais com Mercadorias e Serviços). O Sintegra é uma fonte oficial que fornece informações cadastrais e fiscais de empresas em todo o Brasil, sendo essencial para processos de verificação de regularidade fiscal e compliance.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>CNPJ.</li>
      <li>Razão Social.</li>
      <li>Inscrição Estadual.</li>
      <li>Endereço Completo (logradouro, número, complemento, bairro, cidade, estado, CEP).</li>
      <li>Situação Cadastral (ativa, inapta, cancelada, etc.).</li>
      <li>Atividade Econômica Principal (CNAE).</li>
      <li>Data da Consulta.</li>
    </ul>
    <p>
      Esses dados são fundamentais para empresas que precisam verificar a situação fiscal de parceiros comerciais, fornecedores e clientes, garantindo segurança nas transações e conformidade tributária.
    </p>
    <p>
      Todo o processo de coleta é realizado de maneira rápida, segura e em conformidade com a Lei Geral de Proteção de Dados (LGPD), assegurando a privacidade e proteção dos dados capturados.
    </p>
  </div>
);

export default NSSintegra;
