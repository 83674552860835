import React from 'react';
import './style/Footer.css';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer">
            <a href="/"><img src={logo} alt="Logo da Empresa" className="footer-logo" /></a>
            <nav className="footer-nav">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/empresa">Empresa</Link></li>
                    <li><Link to="/coleta-de-dados">Coleta de Dados</Link></li>
                    <li><Link to="/servicos">Serviços</Link></li>
                    <li><Link to="/lgpd">LGPD</Link></li>
                    <li><Link to="/contato">Contato</Link></li>
                </ul>
            </nav>
            <div className="footer-copy">
                © 2005 - 2024 NS | Soluções inteligentes para empresas.
            </div>
        </footer>
    );
}

export default Footer;
