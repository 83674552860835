import React from 'react';

const NSObitos = () => (
  <div>
    <h2>Consulta e Extração de Dados de Óbitos</h2>
    <div>
      <p>Oferecemos um serviço automatizado para a consulta e extração de dados de óbitos a partir de fontes públicas e oficiais. Essas informações são coletadas diretamente de registros disponíveis na web, permitindo que empresas e instituições acessem dados precisos e atualizados sobre falecimentos.</p>

      <p>As informações capturadas incluem:</p>

      <ul className="ul-coleta">
        <li>
          <p>Nome Completo do falecido.</p>
        </li>
        <li>
          <p>CPF (quando disponível).</p>
        </li>
        <li>
          <p>Data de Óbito.</p>
        </li>
        <li>
          <p>Localidade (cidade, estado).</p>
        </li>
        <li>
          <p>Data e Hora da Consulta.</p>
        </li>
      </ul>

      <p>Esses dados são fundamentais para processos de atualização cadastral, verificação de bases de dados, análise de benefícios e seguros, entre outras finalidades.</p>

      <p>A coleta é realizada de forma eficiente e segura, em conformidade com a Lei Geral de Proteção de Dados (LGPD), garantindo a privacidade e a proteção das informações tratadas.</p>

      <p></p>
    </div>

  </div>
);

export default NSObitos;
