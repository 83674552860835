import React from 'react';
import './style/Contato.css';
import image02 from '../assets/image02.png';

function Contato() {
  return (
    <div className="contato-container">
      <div className="image-section">
        <img src={image02} alt="Contato NS" className="main-image" />
        <div className="overlay-text">
          <h1>Fale com Nossa Equipe Comercial!</h1>
          <p>
            Quer saber como a NS pode impulsionar os resultados da sua empresa? Nossa expertise em Big Data e Inteligência Artificial está pronta para transformar seus dados em decisões mais inteligentes e precisas. Entre em contato conosco e descubra como podemos ajudar sua empresa a maximizar seus ganhos e minimizar perdas com soluções tecnológicas avançadas.
          </p>
          <div className="contact-info">
            <div className="email-card">
              {/* <h2>Email para contato</h2> */}
              <p>
                <a href="mailto:contato@nsmarketing.com.br">contato@nsmarketing.com.br</a>
              </p>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default Contato;
