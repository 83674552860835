import React from 'react';

const NSTelefones = () => (
  <div>
    <h2>Consulta Listas Telefônicas 102</h2>
    <p>
      <strong>Informações publicadas:</strong>
    </p>
    <p>
      Utilizamos tecnologias avançadas de web scraping (crawler) para automatizar a coleta de informações de domínio público disponíveis na internet.
    </p>
    <p>
      Nossa ferramenta é capaz de navegar por diversas fontes, como listas telefônicas, portais públicos e outras bases de dados online, para capturar de forma eficiente e segura informações como:
    </p>
    <ul className="ul-coleta">
      <li>Nome ou Razão Social.</li>
      <li>Logradouro, Número e Complemento.</li>
      <li>Bairro, Cidade, UF e CEP.</li>
      <li>DDD e Telefone.</li>
      <li>Data da Consulta.</li>
    </ul>
    <p>
      Esses dados, publicados em sites acessíveis ao público, são extraídos para enriquecer suas bases de dados, traçar estratégias de negócios, realizar análises geográficas e otimizar o relacionamento com clientes.
    </p>
    <p>
      Nosso sistema pode processar grandes volumes de informações em tempo real, sempre respeitando os parâmetros legais e de segurança.
    </p>
  </div>
);

export default NSTelefones;
