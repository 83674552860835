import React from 'react';

const NSSimplesNacional = () => (
  <div>
    <h2>Consulta Simples Nacional</h2>
    <p>
      Oferecemos um serviço automatizado para a consulta de informações públicas sobre empresas optantes pelo Simples Nacional e pelo SIMEI (Sistema de Recolhimento em Valores Fixos Mensais dos Tributos do Simples Nacional para o Microempreendedor Individual), acessadas diretamente de fontes oficiais.
    </p>
    <p>Essa consulta permite obter dados atualizados sobre a situação fiscal e cadastral de empresas e microempreendedores, auxiliando em processos de análise de crédito, conformidade e planejamento fiscal.</p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>CNPJ da empresa ou MEI.</li>
      <li>Razão Social ou Nome do Microempreendedor.</li>
      <li>Data de Opção pelo Simples Nacional ou SIMEI.</li>
      <li>Situação Atual (ativa, suspensa, excluída, etc.).</li>
      <li>Motivo da Exclusão, quando aplicável.</li>
      <li>Data de Exclusão, quando aplicável.</li>
      <li>Data da Consulta.</li>
    </ul>
    <p>
      Esses dados são essenciais para empresas que precisam verificar a regularidade fiscal de parceiros, fornecedores, clientes e microempreendedores, garantindo segurança em transações comerciais e financeiras.
    </p>
    <p>
      Todo o processo é realizado de maneira segura e em conformidade com a Lei Geral de Proteção de Dados (LGPD), assegurando a privacidade e proteção das informações coletadas.
    </p>
  </div>
);

export default NSSimplesNacional;
