import React, { useEffect, useRef } from 'react';
import './style/Servicos.css';
import img1 from '../assets/cards/data-gathering.png'; 
import img2 from '../assets/cards/data-cleaning.png';
import img3 from '../assets/cards/database.png';
import img4 from '../assets/cards/data-quality.png';

function Servicos() {
  const cardRefs = useRef([]); // Para armazenar referências dos cards

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.2 } // Ajusta esse valor para determinar quando os cards devem aparecer
    );

    // Observa cada card
    cardRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    // Cleanup para parar de observar quando o componente desmonta
    return () => {
      cardRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <div className="servicos-container container">
      <div className="servicos-grid">
        <div className="servico-card" ref={(el) => (cardRefs.current[0] = el)}>
          <img src={img1} alt="Desenvolvimento de softwares" className="card-image" />
          <h2>1. Desenvolvimento de softwares</h2>
          <p>
            A NS desenvolve sistemas personalizados de acordo com a necessidade de negócio e o perfil de cada cliente. Utilizamos as melhores práticas de desenvolvimento de sistemas, garantindo o cumprimento de prazos e dos recursos financeiros negociados. Nossa equipe é composta por profissionais experientes, assegurando um alto nível de conhecimento e performance, o que se reflete diretamente no aumento de produtividade e na redução de custos na construção do software. 
            Além disso, todos os nossos desenvolvimentos são realizados em conformidade com a Lei Geral de Proteção de Dados (LGPD), garantindo que as soluções respeitem a privacidade e a proteção de dados dos nossos clientes e seus usuários.
          </p>
        </div>

        <div className="servico-card" ref={(el) => (cardRefs.current[1] = el)}>
          <img src={img3} alt="Extração de dados" className="card-image" />
          <h2>2. Extração de dados</h2>
          <p>
            Nossos sistemas são customizados para automatizar pesquisas e buscar informações de domínio público em sites, blogs, redes sociais e buscadores, com ou sem captcha. O principal objetivo é extrair pequenas ou grandes quantidades de dados disponíveis no menor tempo e custo possível, permitindo atualizar bases de dados, traçar estratégias econômicas, planejar ações, conhecer o público e antecipar problemas com o uso de estatísticas.
            A coleta de dados é realizada em conformidade com as diretrizes da LGPD, garantindo que as informações de domínio público sejam tratadas com o devido respeito à legislação vigente.
          </p>
        </div>

        <div className="servico-card" ref={(el) => (cardRefs.current[2] = el)}>
          <img src={img2} alt="Tratamento de dados" className="card-image" />
          <h2>3. Tratamento de dados</h2>
          <p>
            Nosso processo de tratamento de dados envolve uma análise rigorosa de correção e validação, incluindo informações como CEP, tipo de logradouro, logradouro, número, complemento, bairro, cidade, UF, DDD, prefixos de telefones, CPF, CNPJ, além da padronização de nomes e sobrenomes incompletos, entre outros atributos.
            O tratamento dos dados segue as diretrizes da LGPD, assegurando que toda informação seja manuseada com segurança, evitando erros e assegurando a qualidade dos dados processados.
          </p>
        </div>

        <div className="servico-card" ref={(el) => (cardRefs.current[3] = el)}>
          <img src={img4} alt="Enriquecimento de dados" className="card-image" />
          <h2>4. Enriquecimento de dados</h2>
          <p>
            Atualizamos e enriquecemos diversas informações de fontes públicas com nossa ferramenta online, garantindo a precisão e a origem atualizada dos dados. Esse processo permite às empresas uma visão completa e qualificada das suas bases de dados.
            Nosso serviço de enriquecimento de dados também está em total conformidade com a LGPD, assegurando que as informações coletadas e tratadas sigam as regulamentações de proteção de dados pessoais.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Servicos;
