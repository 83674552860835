import React from 'react';
import './style/Empresa.css';

function Empresa() {
  return (
    <div className="empresa-container container">
      <h1>Sobre a Empresa</h1>
      <p>
        Com sede em São Paulo, a NS possui grande expertise no desenvolvimento de softwares, aplicativos web e mobile, marketing de banco de dados e mineração de dados para o setor empresarial.
      </p>
      <p>
        Nossa equipe é composta por especialistas em Tecnologia da Informação (TI), Data Base Marketing (DBM), gerenciamento de projetos, gestão de resultados e relacionamento, análise de mailing, geomarketing, além de planejamento operacional e estratégico.
      </p>
      <h2>Nossos pilares principais:</h2>
      <ol>
        <li>Desenvolvimento de soluções – Criamos e aprimoramos sistemas para buscar informações em fontes públicas e privadas na web.</li>
        <li>Higienização e tratamento de dados – Aplicamos processos rigorosos de limpeza e validação de dados.</li>
        <li>Cruzamento de dados por chave Mathcode – Utilizamos tecnologias avançadas para cruzar dados.</li>
        <li>Enriquecimento de dados – Atualizamos bases de dados com atributos diversos.</li>
      </ol>
      <p>
        Todos os nossos sistemas e processamentos são criptografados e monitorados 24 horas por dia.
      </p>
      <h2>Princípios Éticos</h2>
      <p>
        Na NS, acreditamos na importância de promover e desenvolver uma cultura pautada em princípios como respeito, honestidade, responsabilidade e credibilidade.
      </p>
      <p>
        Conduzimos nossos negócios com integridade e sempre em conformidade com as leis, normas e regulamentos vigentes.
      </p>
      <h2>Clientes e Parceiros</h2>
      <p>
        A NS presta serviços para empresas de renome nacional e internacional, incluindo:
      </p>
      <ul>
        <li>Bancos.</li>
        <li>Empresas de TV e Banda Larga no Brasil.</li>
        <li>Empresas de Lista Telefônica.</li>
        <li>Empresas de Crédito e Cobrança.</li>
        <li>Agências de Marketing.</li>
        <li>Financeiras.</li>
        <li>E outros setores.</li>
      </ul>
      <p>
        Devido a contratos de confidencialidade, não divulgamos as logomarcas de nossos clientes.
      </p>
    </div>
  );
}

export default Empresa;
