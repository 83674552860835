import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style/Header.css';
import logo from '../assets/logo.png'; // ajuste a extensão se necessário

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="nav-container">
        <a href="/"><img src={logo} alt="Logo" className="header-logo-image" /></a>
        <p>Soluções inteligentes para empresas</p>
        <button className="hamburger" onClick={toggleMenu}>
          {/* Ícone de hambúrguer */}
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
        {/* Menu para dispositivos maiores */}
        <ul className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/empresa">Empresa</Link></li>
          <li><Link to="/coleta-de-dados">Coleta de Dados</Link></li>
          <li><Link to="/servicos">Serviços</Link></li>
          <li><Link to="/lgpd">LGPD</Link></li>
          <li><Link to="/contato">Contato</Link></li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
