import React from 'react';

const NSMedicos = () => (
  <div>
    <h2>Consulta de Dados de Médicos - CFM</h2>
    <p>
      Oferecemos uma solução automatizada para a consulta de informações públicas sobre médicos registrados no Conselho Federal de Medicina (CFM).
    </p>
    <p>
      Esse serviço é ideal para empresas e instituições de saúde que precisam acessar e validar dados de profissionais médicos de forma rápida e eficiente, diretamente de fontes oficiais.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>Nome Completo do médico.</li>
      <li>Número de Registro (CRM).</li>
      <li>Especialidade(s) registrada(s).</li>
      <li>Estado de atuação.</li>
      <li>Situação do registro (ativo, inativo, etc.).</li>
      <li>Data de Inscrição no conselho.</li>
      <li>Data da Consulta.</li>
    </ul>
    <p>
      Esses dados são essenciais para processos de contratação, validação de credenciais profissionais e análise de conformidade em instituições de saúde.
    </p>
    <p>
      A coleta das informações é realizada de forma segura, respeitando todas as normas da Lei Geral de Proteção de Dados (LGPD), garantindo a privacidade e proteção dos dados tratados.
    </p>
  </div>
);

export default NSMedicos;
