import React from 'react';

const NSCustomizado = () => (
  <div>
    <h2>Consultas Diversas</h2>
    <p>
      Na NS, oferecemos soluções flexíveis e personalizadas para atender qualquer necessidade de captura de dados dos nossos clientes.
    </p>
    <p>
      Analisamos qualquer link fornecido para verificar a viabilidade da extração de dados públicos, utilizando nossas tecnologias avançadas de web scraping e automação.
    </p>
    <p>
      Seja qual for o setor ou o tipo de informação, nossa equipe está pronta para criar soluções que automatizem o processo de coleta de dados de forma eficiente e segura, respeitando todas as regulamentações, incluindo a Lei Geral de Proteção de Dados (LGPD).
    </p>
    <p>
      Nossa meta é garantir que você tenha acesso rápido e preciso às informações necessárias para seu negócio.
    </p>
  </div>
);

export default NSCustomizado;
