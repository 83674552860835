import React from 'react';

const NSUniversitarios = () => (
  <div>
    <h2>Consulta e Extração de Dados de Estudantes e Universitários</h2>
    <p>
      Oferecemos uma solução automatizada para a extração de dados de estudantes e universitários a partir de listas e registros públicos disponíveis em sites oficiais. Esse serviço é ideal para instituições educacionais, agências de marketing, empresas de recrutamento e outras organizações que precisam acessar informações sobre estudantes para diversas finalidades.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>Nome Completo do estudante.</li>
      <li>Instituição de Ensino.</li>
      <li>Curso e Área de Estudo.</li>
      <li>Matrícula (quando disponível).</li>
      <li>Data de Ingresso e/ou Conclusão.</li>
      <li>Localidade (cidade, estado).</li>
      <li>Data da Consulta.</li>
    </ul>
    <p>
      Esses dados são essenciais para processos de recrutamento, verificação acadêmica, campanhas direcionadas e análises de mercado educacional.
    </p>
    <p>
      A coleta das informações é realizada de forma eficiente e em conformidade com a Lei Geral de Proteção de Dados (LGPD), assegurando a privacidade e a segurança dos dados coletados.
    </p>
  </div>
);

export default NSUniversitarios;
