import React from 'react';

const NSSituacaoCadastral = () => (
  <div>
    <h2>Consulta Situação Cadastral PF</h2>
    <p>
      Oferecemos um serviço automatizado para consultar a situação cadastral de pessoas físicas diretamente de fontes públicas e confiáveis, como a Receita Federal do Brasil.
    </p>
    <p>
      Essa consulta permite verificar a situação do CPF, proporcionando informações essenciais para validação de cadastros, análises de crédito e compliance.
    </p>
    <p>As informações obtidas na consulta incluem:</p>
    <ul className="ul-coleta">
      <li>CPF.</li>
      <li>Nome Completo.</li>
      <li>Data de Nascimento.</li>
      <li>Situação Cadastral (regular, suspensa, cancelada, nula, ou pendente de regularização).</li>
      <li>Data e Hora da Consulta.</li>
    </ul>
    <p>
      Esse serviço é ideal para empresas que precisam validar a regularidade do CPF em processos de contratação, concessão de crédito, análise de clientes ou parceiros comerciais. 
      As consultas são realizadas de forma rápida e segura, sempre em conformidade com a Lei Geral de Proteção de Dados (LGPD), garantindo a proteção e privacidade das informações coletadas.
    </p>
  </div>
);

export default NSSituacaoCadastral;
