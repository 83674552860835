import React from 'react';

const NSPortabilidade = () => (
  <div>
    <h2>Consulta Portabilidade de Telefonia Detalhada</h2>
    <p>
      Nossa solução automatizada permite a consulta de informações públicas sobre a portabilidade de números de telefone, capturando dados essenciais para empresas que precisam monitorar ou validar a operadora de origem e atual de seus contatos. Essas informações são coletadas diretamente de fontes oficiais, garantindo precisão e agilidade no processo.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>Número de Telefone.</li>
      <li>Razão Social da Operadora.</li>
      <li>Operadora de Origem.</li>
      <li>Data e Hora da Portabilidade.</li>
      <li>Operadora Atual.</li>
      <li>Data da Consulta.</li>
    </ul>
    <p>
      Esses dados são fundamentais para empresas que atuam nos setores de telecomunicações, crédito, marketing ou serviços, permitindo uma gestão eficiente e a verificação da operadora de contato para reduzir custos operacionais.
    </p>
    <p>
      A coleta das informações é realizada de forma segura e em total conformidade com a Lei Geral de Proteção de Dados (LGPD), assegurando a privacidade e segurança dos dados tratados.
    </p>
  </div>
);

export default NSPortabilidade;
