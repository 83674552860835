import React from 'react';

const NSPerfilBR = () => (
  <div>
    <h2>Captura de Dados em Massa</h2>
    <p>
      Utilizamos tecnologias avançadas de web crawling e web scraping para capturar bilhões de dados públicos disponíveis na web. Nossa solução automatizada é capaz de extrair grandes volumes de informações de diversas fontes, como sites, portais governamentais, blogs, redes sociais e bancos de dados abertos.
    </p>
    <p>
      Esses dados são posteriormente estruturados e analisados para criar perfis detalhados de usuários e empresas.
    </p>
    <p>Os dados capturados incluem:</p>
    <ul className="ul-coleta">
      <li>Informações Pessoais e Corporativas (como nomes, CPFs, CNPJs, endereços, etc.).</li>
      <li>Dados de Contato (telefones, e-mails, entre outros).</li>
      <li>Atividades e Transações (histórico de movimentações e comportamentos online).</li>
      <li>Dados Financeiros (informações de receita, dívidas, patrimônio, etc.).</li>
      <li>Dados Públicos Governamentais (relacionados a empresas e indivíduos).</li>
    </ul>
    <p>
      Após a captura, esses dados passam por um processo rigoroso de estruturação e tratamento, permitindo a criação de perfis robustos e detalhados que auxiliam em análises de mercado, planejamento estratégico, gestão de riscos, avaliação de crédito, entre outras finalidades.
    </p>
    <p>
      Nossa tecnologia oferece a vantagem de transformar dados brutos em informações valiosas e organizadas, prontas para serem utilizadas em qualquer contexto empresarial.
    </p>
    <p>
      Todo o processo de captura e tratamento de dados é realizado em conformidade com as regulamentações da Lei Geral de Proteção de Dados (LGPD), garantindo que a privacidade e segurança das informações sejam sempre priorizadas.
    </p>
  </div>
);

export default NSPerfilBR;
