import React from 'react';

const NSTup = () => (
  <div>
    <h2>Atualização de TUP (Orelhões) do Brasil</h2>
    <p>
      Oferecemos um serviço automatizado para a consulta de informações públicas sobre Telefones de Uso Público (TUP), coletadas diretamente de fontes oficiais.
    </p>
    <p>
      Essas informações são essenciais para empresas e órgãos que precisam monitorar a disponibilidade e as condições dos telefones públicos, garantindo a acessibilidade e o cumprimento das regulamentações.
    </p>
    <p>As informações capturadas incluem:</p>
    <ul className="ul-coleta">
      <li>Status do TUP.</li>
      <li>Número do Telefone Público (TUP).</li>
      <li>Data de Ativação do telefone.</li>
      <li>Data do Último Status.</li>
      <li>Estado, Município e Localidade.</li>
      <li>Bairro, Endereço, Complemento e CEP.</li>
      <li>Adaptado para Cadeirantes.</li>
      <li>Adaptado para Deficientes Auditivos e da Fala.</li>
      <li>Funcionamento 24 horas, quando aplicável.</li>
    </ul>
    <p>
      Esses dados são fundamentais para análises de acessibilidade, conformidade com normas públicas e para o planejamento da infraestrutura de comunicação em diversas localidades.
    </p>
    <p>
      Todo o processo de consulta é realizado de maneira eficiente e com total conformidade com a Lei Geral de Proteção de Dados (LGPD), assegurando a proteção e privacidade das informações tratadas.
    </p>
  </div>
);

export default NSTup;
